<script setup>
import { v4 as uuidv4 } from "uuid";

const props = defineProps({
  deviceTarget: {
    type: Object,
  },
  deviceTargetForm: {
    type: Object,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },

  responseError: {
    type: Object,
  },
});

const addDeviceField = () => {
  if (props.deviceTargetForm?.length <= 4)
    props.deviceTargetForm.push({
      target_destination_url: null,
      deviceName: null,
      primary_key: "device",
      uuid: uuidv4(),
    });
};

const removeDeviceField = (index) => {
  if (index >= 0 && index < props.deviceTargetForm.length) {
    props.deviceTargetForm.splice(index, 1);
  }
};

const deviceOptions = ref([
  { name: "Android", value: "android" },
  { name: "IOS", value: "ios" },
  { name: "Windows", value: "windows" },
  { name: "Mac", value: "mac" },
  { name: "Other", value: "other" },
]);

const getAvailableOptions = (index) => {
  return deviceOptions.value.filter((device) => {
    return !props.deviceTargetForm.some(
      (item, idx) => idx !== index && item.deviceName === device.value
    );
  });
};

const hasInputErrors = (input, index) => {
  const errors = props.deviceTarget.$each.$response.$errors[index];
  return (
    errors.target_destination_url?.length ||
    errors.deviceName?.length ||
    (props.responseError?.details?.form_key === "device_target_destination_url" &&
      props.responseError?.details?.url === input.target_destination_url)
  );
};

const hasDeviceErrors = (index) => {
  const errors = props.deviceTarget.$each.$response.$errors[index];
  return errors.deviceName?.length;
};

const getInputErrorMessage = (input, index) => {
  const errors = props.deviceTarget.$each.$response.$errors[index];
  if (errors.target_destination_url?.length) {
    return `Oh, snap! ${errors.target_destination_url[0].$message.replace(
      "Value",
      "Destination URL"
    )}`;
  } else if (errors.deviceName?.length) {
    return `Oh, snap! ${errors.deviceName[0].$message}`;
  }
  return "";
};
</script>
<template>
  <div class="field px-1" v-for="(input, index) in deviceTargetForm" :key="input.uuid">
    <div class="flex items-center justify-between mb-1">
      <label
        :for="`device-target-url-input-field-${index}`"
        class="block mb-2 text-sm font-medium"
        :class="{
          'text-red-700 dark:text-red-500': hasInputErrors(input, index),
        }"
      >
        Target Device {{ index + 1 }}
      </label>

      <Button
        outlined
        rounded
        aria-label="remove"
        class="!border-red-500 !bg-transparent border !p-1"
        @click="removeDeviceField(index)"
      >
        <i class="pi pi-times text-[0.5rem] font-bold text-red-500"></i>
      </Button>
    </div>

    <!-- url input field -->
    <div class="flex justify-between">
      <IconField iconPosition="right" class="w-[70%] mr-2">
        <InputIcon
          v-if="hasInputErrors(input, index)"
          class="pi pi-exclamation-triangle !text-red-500"
        />
        <input
          v-model.trim="input.target_destination_url"
          type="url"
          :id="'device-target-url-input-field-' + index"
          class="input-field"
          :class="{
            'input-error': hasInputErrors(input, index),
          }"
          placeholder="Enter a destination url here"
          :aria-describedby="'device-target-url-input-field-' + index"
        />
      </IconField>

      <!-- device dropdown -->
      <Dropdown
        :id="`device-target-device-dropdown-${index}`"
        class="p-inputgroup-addon device-target-device-dropdown !p-0 w-[30%]"
        :class="{
          'input-error': hasDeviceErrors(index),
        }"
        :inputClass="{
          'input-error': hasDeviceErrors(index),
        }"
        v-model="input.deviceName"
        :options="getAvailableOptions(index)"
        optionLabel="name"
        optionValue="value"
        placeholder="Select a Device"
      />
    </div>

    <!-- help text -->
    <small :id="`device-target-url-input-field-${index}-help`" class="mb-1 block"
      >Please include 'https://' or 'http://' in the link.</small
    >
    <!-- errors -->
    <p v-if="hasInputErrors(input, index)" class="input-error-message">
      {{ getInputErrorMessage(input, index) }}
    </p>
    <br />
  </div>

  <!-- add device -->
  <Button
    v-if="deviceTargetForm?.length <= 4"
    @click="addDeviceField()"
    label="Add Device"
    icon="pi pi-plus"
    class="p-button-sm !mr-2 !p-2"
  />
  <br />
</template>

<style lang="postcss">
.device-target-device-dropdown.p-dropdown:not(.p-disabled).p-focus {
  @apply !border-[#d4d4d8] shadow-none;
}
#remove-device-btn {
  @apply !bg-white !border-white;
}

.input-error {
  @apply border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500;
}

.input-field {
  @apply block p-3 bg-gray-50 border sm:text-base rounded-lg dark:bg-gray-700 w-full;
}

.input-error-message {
  @apply mt-2 text-sm text-red-600 dark:text-red-500;
}
</style>
