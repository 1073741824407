<script setup>
import { v4 as uuidv4 } from "uuid";

const props = defineProps({
  browserTarget: {
    type: Object,
  },
  browserTargetForm: {
    type: Object,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },

  responseError: {
    type: Object,
  },
});

const addBrowserField = () => {
  if (props.browserTargetForm?.length <= 4)
    props.browserTargetForm.push({
      target_destination_url: null,
      browserName: null,
      primary_key: "browser",
      uuid: uuidv4(),
    });
};

const removeBrowserField = (index) => {
  if (index >= 0 && index < props.browserTargetForm.length) {
    props.browserTargetForm.splice(index, 1);
  }
};

const browserOptions = ref([
  { name: "Chrome", value: "chrome" },
  { name: "Firefox", value: "firefox" },
  { name: "Edge", value: "edge" },
  { name: "Opera", value: "opera" },
  { name: "Safari", value: "safari" },
  { name: "Other", value: "other" },
]);

const getAvailableOptions = (index) => {
  return browserOptions.value.filter((browser) => {
    return !props.browserTargetForm.some(
      (item, idx) => idx !== index && item.browserName === browser.value
    );
  });
};

const hasInputErrors = (input, index) => {
  const errors = props.browserTarget.$each.$response.$errors[index];
  return (
    errors.target_destination_url?.length ||
    errors.browserName?.length ||
    (props.responseError?.details?.form_key === "browser_target_destination_url" &&
      props.responseError?.details?.url === input.target_destination_url)
  );
};

const hasBrowserErrors = (index) => {
  const errors = props.browserTarget.$each.$response.$errors[index];
  return errors.browserName?.length;
};

const getInputErrorMessage = (input, index) => {
  const errors = props.browserTarget.$each.$response.$errors[index];
  if (errors.target_destination_url?.length) {
    return `Oh, snap! ${errors.target_destination_url[0].$message.replace(
      "Value",
      "Destination URL"
    )}`;
  } else if (errors.browserName?.length) {
    return `Oh, snap! ${errors.browserName[0].$message}`;
  }
  return "";
};
</script>
<template>
  <div class="field px-1" v-for="(input, index) in browserTargetForm" :key="input.uuid">
    <div class="flex items-center justify-between">
      <label
        :for="`browser-target-url-input-field-${index}`"
        class="block mb-2 text-sm font-medium"
        :class="{
          'text-red-700 dark:text-red-500': hasInputErrors(input, index),
        }"
      >
        Target Browser {{ index + 1 }}
      </label>

      <Button
        outlined
        rounded
        size="small"
        aria-label="remove"
        class="!border-red-500 !bg-transparent border !p-1"
        @click="removeBrowserField(index)"
      >
        <i class="pi pi-times text-[0.5rem] font-bold text-red-500"></i>
      </Button>
    </div>

    <!-- url input field -->
    <div class="flex justify-between">
      <IconField iconPosition="right" class="w-[70%] mr-2">
        <InputIcon
          v-if="hasInputErrors(input, index)"
          class="pi pi-exclamation-triangle !text-red-500"
        />

        <input
          v-model.trim="input.target_destination_url"
          type="url"
          :id="'browser-target-url-input-field-' + index"
          class="input-field"
          :class="{
            'input-error': hasInputErrors(input, index),
          }"
          placeholder="Enter a destination url here"
          :aria-describedby="'browser-target-url-input-field-' + index"
        />
      </IconField>

      <!-- device dropdown -->
      <Dropdown
        :id="`browser-target-browser-dropdown-${index}`"
        class="p-inputgroup-addon browser-target-browser-dropdown !p-0 w-[30%]"
        :class="{
          'input-error': hasBrowserErrors(index),
        }"
        :inputClass="{
          'input-error': hasBrowserErrors(index),
        }"
        v-model="input.browserName"
        :options="getAvailableOptions(index)"
        optionLabel="name"
        optionValue="value"
        placeholder="Select a Browser"
      />
    </div>

    <!-- help text -->
    <small :id="`browser-target-url-input-field-${index}-help`" class="mb-1 block"
      >Please include 'https://' or 'http://' in the link.</small
    >

    <!-- errors -->
    <p v-if="hasInputErrors(input, index)" class="input-error-message">
      {{ getInputErrorMessage(input, index) }}
    </p>
    <br />
  </div>

  <Button
    v-if="browserTargetForm?.length <= 4"
    @click="addBrowserField()"
    label="Add Browser"
    icon="pi pi-plus"
    class="p-button-sm !mr-2 !p-2"
  />
  <br />
</template>

<style lang="postcss">
.browser-target-browser-dropdown.p-dropdown:not(.p-disabled).p-focus {
  @apply !border-[#d4d4d8] shadow-none;
}
#remove-browser-btn {
  @apply !bg-white !border-white;
}

.input-error {
  @apply border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500;
}

.input-field {
  @apply block p-3 bg-gray-50 border sm:text-base rounded-lg dark:bg-gray-700 w-full;
}

.input-error-message {
  @apply mt-2 text-sm text-red-600 dark:text-red-500;
}
</style>
