import { defineStore } from "pinia";

export const useHomeStoreStore = defineStore("HomeStore", () => {
  if (process.server) return;

  const isUrlInputFocused = ref(false);

  const setUrlInputFocus = (state) => {
    isUrlInputFocused.value = state;
  };

  return { isUrlInputFocused, setUrlInputFocus };
});
