<script setup>
import { storeToRefs } from "pinia";
import { useLinksTableUtilityApi } from "~/composables/links-table/useLinksTableUtilityApi.js";
import { linksUtilityApiService } from "~/services/linksUtilityApiService.js";
import { useAuthStore } from "~/store/auth.js";
import { notifyError } from "~/utils/notifications";
import { restrictedPrefixChars, restrictedAliasChars } from "~/utils/string";
import { PUBLIC_DOMAINS } from "~/utils/constants";

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  formV$: {
    type: Object,
    required: true,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },
  responseError: {
    type: Object,
  },
  clearResponseError: {
    type: Function,
    required: true,
  },
});

const collectionId = inject("collection-id");

const { form, formV$, optional, clearResponseError } = props;
const { isAuthenticated } = storeToRefs(useAuthStore());
const {
  fetchDomains,
  fetchPrefix,
  domainsData,
  prefixData,
  loading: utilsLoading,
} = useLinksTableUtilityApi({ collectionId });
const { checkSmartDomainAvailability } = linksUtilityApiService();

// ===============================================================
// FETCH DOMAINS
// ===============================================================
const computedDomainsOptions = computed(() => {
  let defaultDomains = [];
  if (form.domain) {
    defaultDomains.push({
      label: form.domain,
      value: form.domain,
    });
  }

  const publicDomains = PUBLIC_DOMAINS.map((domain) => ({
    label: domain,
    value: domain,
  }));

  const domainsOptions =
    domainsData.value?.map((domain) => ({
      label: domain.domain_name,
      value: domain.domain_name,
    })) || [];

  // updating to the new domain
  if (domainsOptions.length && !form.domain) form.domain = domainsOptions[0].value;

  // Combine and remove duplicates
  const combinedDomains = [...defaultDomains, ...domainsOptions, ...publicDomains];
  const uniqueDomains = Array.from(new Set(combinedDomains.map(JSON.stringify))).map(
    JSON.parse
  );

  return uniqueDomains;
});

const addDomainInDropdown = (domain) => {
  const index = domainsData.value?.findIndex((d) => d.value === domain.value);
  if (index === -1) {
    domainsData.value.push(domain);
  }
};

const removeDomainFromDropdown = (domain) => {
  const index = domainsData.value?.findIndex((d) => d.value === domain.value);
  if (index !== -1) {
    domainsData.value?.splice(index, 1);
  }
};

// ===============================================================
// CHECK LINK AVAILABILITY
// ===============================================================
const isCheckingAvailablity = ref(false);
const isSmartDomainAvailble = ref();
const checkLinkAvailability = async () => {
  isCheckingAvailablity.value = true;

  if (form.prefix?.length >= 3 && form.domain) {
    const params = {
      prefix: form.prefix,
      postfix: form.postfix,
      domain: form.domain,
    };
    const response = await checkSmartDomainAvailability(params);
    if (response?.status === 200) {
      isSmartDomainAvailble.value = response.data.data?.availability;
    } else {
      notifyError({ title: response.data?.message });
    }
  }
  isCheckingAvailablity.value = false;
};

// ==========================================================================
// ON DROPDOWN VALUE CHANGE
// ==========================================================================
function onDropdownValueChange() {
  resetlinkAvailability();
}
// ==========================================================================
// RESET LNK Available
// ==========================================================================
function resetlinkAvailability() {
  isSmartDomainAvailble.value = undefined;
  clearResponseError();
}

function resetlinkAvailablePrefix(event) {
  if (typeof event.value === "object" && event.value && "prefix" in event.value) {
    formV$.prefix.$model = event.value.prefix;
  }
  resetlinkAvailability();
}

// ===============================================================
// FETCH COLLECTION PREFIX
// ===============================================================
const isPrefixSelected = ref(false);
const filteredPrefix = ref([]);
const onPrefixComplete = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredPrefix.value = [...prefixData.value];
    } else {
      filteredPrefix.value = prefixData.value?.filter((data) => {
        return data.prefix?.toLowerCase()?.startsWith(event.query?.toLowerCase());
      });
    }
  }, 250);
};

function checkPrefixValue(value) {
  isPrefixSelected.value =
    formV$.prefix.$model != null ? true : formV$.prefix.$model?.length >= 3;
}

const preventRestrictedCharactersPrefix = (event) => {
  if (
    restrictedPrefixChars.includes(event.key) ||
    event.key === "\n" ||
    event.key === "\r"
  ) {
    event.preventDefault();
  }
};

const preventRestrictedCharactersAlias = (event) => {
  if (
    restrictedAliasChars.includes(event.key) ||
    event.key === "\n" ||
    event.key === "\r"
  ) {
    event.preventDefault();
  }
};

const handleOnAliasPaste = (event) => {
  const pastedText = (event.clipboardData || window?.clipboardData).getData("text");

  const foundRestrictedChars = restrictedAliasChars.filter((char) =>
    pastedText.includes(char)
  );

  if (
    restrictedAliasChars.some((char) => pastedText.includes(char)) ||
    pastedText.includes("\n") ||
    pastedText.includes("\r")
  ) {
    event.preventDefault();
    const formattedChars = foundRestrictedChars
      .map((char) => (char === "\n" ? "\\n" : char === "\r" ? "\\r" : char))
      .join(", ");

    notifyError({
      title: `Your paste contains restricted characters that are not allowed: ${formattedChars}. Please remove them and try again.`,
    });
  }
};

const handleOnPrefixPaste = (event) => {
  const pastedText = (event.clipboardData || window?.clipboardData).getData("text");

  const foundRestrictedChars = restrictedPrefixChars.filter((char) =>
    pastedText.includes(char)
  );

  if (
    restrictedPrefixChars.some((char) => pastedText.includes(char)) ||
    pastedText.includes("\n") ||
    pastedText.includes("\r")
  ) {
    event.preventDefault();
    const formattedChars = foundRestrictedChars
      .map((char) => (char === "\n" ? "\\n" : char === "\r" ? "\\r" : char))
      .join(", ");

    notifyError({
      title: `Your paste contains restricted characters that are not allowed: ${formattedChars}. Please remove them and try again.`,
    });
  }
};

onMounted(() => {
  if (isAuthenticated.value) {
    fetchDomains({ linkType: "smartdomains" });
    fetchPrefix();
  }
});
</script>
<template>
  <div v-if="utilsLoading" class="loading-overlay rounded-lg">
    <LoadersRingLoader />
  </div>
  <div class="field">
    <label
      for="smartdomain-input-field"
      class="block mb-2 text-sm font-medium"
      :class="{
        'text-red-700 dark:text-red-500':
          (form.prefix?.length < 3 && formV$.prefix.$invalid) || formV$.postfix.$invalid,
      }"
      >SmartDomain</label
    >

    <div class="grid grid-cols-6">
      <AutoComplete
        id="smartdomain-dropdown"
        class="p-inputgroup-addon !p-0 !border-none col-span-6 md:col-span-2"
        inputClass="!bg-gray-50 dark:!bg-gray-700 !border-gray-300 !text-gray-900 !text-sm !py-[.85rem] focus:!border-[#d4d4d8]"
        v-model="formV$.prefix.$model"
        :suggestions="filteredPrefix"
        :selectOnFocus="true"
        @item-select="isPrefixSelected = true"
        @complete="onPrefixComplete($event)"
        @keyup="resetlinkAvailablePrefix($event)"
        @change="resetlinkAvailablePrefix($event)"
        :dropdown="true"
        dropdownMode="current"
        optionLabel="prefix"
        placeholder="Select or Enter prefix"
        emptySearchMessage="No prefix found!"
        @keydown.space.prevent
        @keydown="preventRestrictedCharactersPrefix"
        @paste="handleOnPrefixPaste"
      >
      </AutoComplete>

      <Dropdown
        v-model="formV$.domain.$model"
        :options="computedDomainsOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Select a domain"
        class="w-full col-span-6 md:col-span-2 my-8 md:my-0 !bg-gray-50 dark:!bg-gray-700 !border-gray-300 !text-gray-900 !text-sm focus:!border-[#d4d4d8] md:!rounded-r-none md:!rounded-l-none md:!border-l-0"
        id="select-custom-domain-dropdown"
        :filter="false"
        @change="onDropdownValueChange()"
      />
      <div class="relative col-span-6 md:col-span-2">
        <IconField iconPosition="right">
          <InputIcon
            v-if="formV$.postfix.$invalid"
            class="pi pi-exclamation-triangle !text-red-500"
          />

          <input
            v-model.trim="formV$.postfix.$model"
            @keyup="resetlinkAvailability()"
            @keydown="preventRestrictedCharactersAlias"
            @paste="handleOnAliasPaste"
            type="text"
            id="postfix-input-field"
            class="block w-full p-3.5 bg-gray-50 border md:border-l-0 sm:text-md rounded-r-lg dark:bg-gray-700 border-gray-300 text-gray-900 text-sm pl-[0.8rem]"
            :class="{
              'border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500':
                formV$.postfix.$invalid,
            }"
            placeholder="Enter a descriptive postfix"
            aria-describedby="postfix-input-field"
          />
        </IconField>
      </div>
    </div>
    <div
      v-if="formV$.prefix.$model?.length < 3 && formV$.prefix.$errors"
      class="input-errors"
      v-for="(error, index) of formV$.prefix.$errors"
      :key="index"
    >
      <p id="prefix-input-error" class="mt-2 text-sm text-red-600 dark:text-red-500">
        <span class="font-medium">Oh, snapp!</span>
        {{ error.$message.replace("This field", "Prefix") }}.
      </p>
    </div>
    <p
      v-else-if="(formV$.prefix.$invalid || formV$.prefix.$pending) && isSubmitted"
      class="input-errors mt-2 text-sm text-red-600 dark:text-red-500"
    >
      <span class="font-medium">Oh, snapp!</span>
      {{ formV$.prefix.required.$message }}.
    </p>
    <p
      v-else-if="(formV$.domain.$invalid || formV$.domain.$pending) && isSubmitted"
      class="input-errors mt-2 text-sm text-red-600 dark:text-red-500"
    >
      <span class="font-medium">Oh, snapp!</span>
      {{ formV$.domain.required.$message }}.
    </p>
    <div
      v-else-if="formV$.postfix.$errors"
      class="input-errors"
      v-for="error of formV$.postfix.$errors"
      :key="error.$uid"
    >
      <p id="postfix-input-error" class="mt-2 text-sm text-red-600 dark:text-red-500">
        <span class="font-medium">Oh, snapp!</span>
        {{ error.$message.replace("This field", "Postfix") }}.
      </p>
    </div>

    <p
      v-if="isSmartDomainAvailble != undefined"
      id="postfix-input-error"
      class="block mt-2 text-sm"
      :class="
        isSmartDomainAvailble
          ? 'text-green-600 dark:text-green-500'
          : 'text-red-600 dark:text-red-500'
      "
    >
      <span v-if="isSmartDomainAvailble">
        <span class="font-medium"> Great!</span> SmartDomain Available</span
      >
      <span v-else
        ><span class="font-medium"> Unlucky!</span> SmartDomain Not Available</span
      >
    </p>

    <Button
      v-if="
        form.domain?.length &&
        form.prefix?.length > 2 &&
        isSmartDomainAvailble === undefined &&
        formV$.domain.$errors.length === 0 &&
        formV$.prefix.$errors.length === 0 &&
        formV$.postfix.$errors.length === 0
      "
      @click="checkLinkAvailability()"
      class="mt-0 text-black font-medium text-sm cursor-pointer"
      label="check availability"
      id="check-availability-btn"
      text
      :loading="isCheckingAvailablity"
    />
  </div>
  <br />
</template>

<style lang="postcss">
#smartdomain-dropdown > button {
  @apply !border !border-[#d4d4d8] text-gray-500 rounded-r-md md:rounded-none;
}

#smartdomain-dropdown > input {
  @apply md:border-r-0 lowercase;
}

#smartdomain-dropdown > .p-inputtext:enabled:focus {
  border-color: rgb(212, 212, 216) !important;
}

#select-custom-domain-dropdown.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  @apply !border-[#d4d4d8];
}
</style>
