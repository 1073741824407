<script setup>
import { form, formV$ } from "~/validations/homeLinkFormRules";
import { useHomeStoreStore } from "~/store/useHomeStore";

const homeStore = useHomeStoreStore();

const shouldShowSocialIcon = computed(() => {
  const isUrlValid = !formV$.value.destinationURL.$error;
  const hasUrlInput = form.destinationURL.length > 0;
  return isUrlValid && hasUrlInput;
});

watch(
  () => homeStore.isUrlInputFocused,
  (isFocused) => {
    if (isFocused) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
);
</script>

<template>
  <div class="relative w-full flex items-center justify-center z-30">
    <!-- Blurred Background -->
    <div
      v-if="homeStore.isUrlInputFocused"
      class="fixed inset-0 z-10 bg-black/30 backdrop-blur transition duration-300 overflow-hidden"
    ></div>

    <span class="p-input-icon-left w-full flex items-center justify-center">
      <span class="absolute left-2.5 z-50">
        <i>
          <CustomSocialIcons
            v-if="shouldShowSocialIcon"
            :url="form.destinationURL"
            :dynamicClass="'!h-9 !w-9'"
          />
        </i>
      </span>
      <input
        type="search"
        id="search"
        class="block w-full p-5 pl-5 pr-32 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black z-30"
        :class="{
          '!pl-14': shouldShowSocialIcon,
        }"
        placeholder="Enter or paste any url"
        required
        v-model.trim="formV$.destinationURL.$model"
        @focus="homeStore.setUrlInputFocus(true)"
        @blur="homeStore.setUrlInputFocus(false)"
      />
    </span>
    <HomeSmartenItBtn />
  </div>
</template>

<style></style>
