<script setup>
defineProps({
  Field: {
    type: Object,
  },
  label: {
    type: String,
  },
  inputId: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  isSubmitted: {
    type: Boolean,
  },
  isDisabled: {
    type: Boolean,
  },
  extraInputClass: {
    type: Boolean,
  },
  responseError: {
    type: Object,
  },
  showResponseError: {
    type: Boolean,
  },
  helpText: {
    type: String,
  },
});
const emit = defineEmits(["clearResponseError"]);
function clearResponseError() {
  emit("clearResponseError");
}
</script>

<template>
  <div class="field">
    <div>
      <label
        :for="inputId"
        class="block mb-2 text-sm font-medium"
        :class="{
          '!text-red-700 dark:!text-red-500':
            (Field.$invalid && isSubmitted) || (responseError && isSubmitted),
        }"
        >{{ label }}</label
      >
      <IconField iconPosition="right">
        <InputIcon
          v-if="(Field.$invalid && isSubmitted) || (responseError && isSubmitted)"
          class="pi pi-exclamation-triangle !text-red-500"
        />

        <input
          :disabled="isDisabled ? true : false"
          v-model.trim="Field.$model"
          @keyup="clearResponseError()"
          type="text"
          :id="inputId"
          class="block w-full p-3 bg-gray-50 dark:bg-[#181818] border sm:text-md rounded-lg dark:border-[#1f1f1f]"
          :class="[
            {
              '!border-red-500 !text-red-900 !placeholder-red-700 focus:!ring-red-500 focus:!border-red-500 dark:!text-red-500 dark:!placeholder-red-500 dark:!border-red-500':
                (Field.$invalid && isSubmitted) || (responseError && isSubmitted),
            },
            extraInputClass,
            { 'cursor-not-allowed': isDisabled },
          ]"
          :placeholder="placeholder"
          :aria-describedby="inputId"
        />
      </IconField>
      <small :id="`${inputId}-help`">{{ helpText }}</small>

      <div
        v-if="Field.$error && isSubmitted"
        class="input-errors"
        v-for="error of Field.$errors"
        :key="error.$uid"
      >
        <p class="mt-2 text-sm text-red-600 dark:text-red-500">
          <span class="font-medium">Oh, snapp!</span>
          {{ error.$message }}.
        </p>
      </div>
      <p
        v-else-if="(Field.$invalid && isSubmitted) || (Field.$pending && isSubmitted)"
        class="input-errors mt-2 text-sm text-red-600 dark:text-red-500"
      >
        <span class="font-medium">Oh, snapp!</span>
        {{ Field.required.$message }}.
      </p>
      <p
        v-else-if="responseError && showResponseError"
        id="current-password-input-error"
        class="input-errors mt-2 text-sm text-red-600 dark:text-red-500"
      >
        <span class="font-medium">Oh, snapp!</span>
        {{ responseError }}
      </p>
    </div>
  </div>
  <br />
</template>

<style></style>
